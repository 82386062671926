<template>
  <div class="container">
    <GeneralTable
      ref="GeneralTable"
      :column="column"
      tableName="jxStaffManagementList"
      :option="option"
      :dataList="dataList"
      :totalNum="totalNum"
      @search-change="getList"
      @export-data="exportData"
      @import-click="isImport = true"
      @del-change="handleDel"
    >
      <template slot="deptName">
        <selectorStaff v-model="ruleForm.deptId" />
      </template>
      <template slot="status" slot-scope="{ item }">
        <el-tag type="success" v-if="item.status">启用</el-tag>
        <el-tag type="danger" v-else>禁用</el-tag>
      </template>
      <template slot="operate" slot-scope="{ item }">
        <el-button
          class="polecy-button"
          @click.native.prevent="editDetail(item)"
          type="text"
          size="small"
          >查看详情</el-button
        >
        <!-- <el-button
          class="polecy-button"
          style="color: #f64a2dff"
          @click.native.prevent="handleDel([item])"
          type="text"
          size="small"
          >删除</el-button
        > -->
      </template>
    </GeneralTable>
    <AddRegulationDialog
      v-model="isAddVisible"
      :editDetail="editData"
      @changeList="changeGetList"
    />
  </div>
</template>
<script>
import GeneralTable from "@/components/GeneralTable/index.vue";
import selectorStaff from "@/views/SystemManagement/components/staff/selectorStaff.vue";
import AddRegulationDialog from "@/views/routerPerformanceManagement/components/regulationManagementList/components/AddRegulationDialog.vue";
//import { userPerformanceDownload } from "@/api/policy";
import {
  getTeamKpiList,
  deleteStaffKpi,
  exportTeamKpiList,
} from "@/api/kpi.js";
import dayjs from "dayjs";
export default {
  name: "ProjectManagementList",
  components: { GeneralTable, AddRegulationDialog, selectorStaff },
  data() {
    return {
      dataList: [],
      totalNum: 0,
      option: {
        isDel: false,
        isRefresh: true, // 刷新 @search-change
        isSearch: true,
        isExport: true, // 导入 @import-click
        disFuzzySearch: true,
        pageSize: 10,
        searchList: [
          {
            label: "姓名",
            prop: "account",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            // isPermissions: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, "policyNo"),
          },
          // {
          //   label: "岗位",
          //   prop: "postName",
          //   formType: "input",
          //   maxLength: 32,
          //   clearable: true,
          //   placeholder: "请输入",
          //   // isPermissions: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, "policyNo"),
          // },
          {
            label: "绩效周期",
            prop: "startDate",
            format: "yyyy-MM",
            formType: "monthDate",
            clearable: true,
            // prop: ["createStartDateTime", "createEndDateTime"],
          },
          {
            label: "所属部门",
            prop: "deptName",
            formType: "range-input",
          },
        ],
      },
      column: [
        {
          label: "序号",
          type: "index",
          isShow: true,
          fixed: "left",
        },
        {
          label: "姓名",
          prop: "account",
          isShow: true,
        },
        {
          label: "员工编号",
          prop: "code",
          isShow: true,
        },
        // {
        //   label: "岗位",
        //   prop: "postName",
        //   isShow: true,
        // },
        {
          label: "所属部门",
          prop: "deptName",
          isShow: true,
        },
        {
          label: "绩效周期",
          prop: "yearMonth",
          isShow: true,
        },
        // {
        //   label: "本月保费",
        //   prop: "currentPremium",
        //   isShow: true,
        // },
        // {
        //   label: "本月个人绩效",
        //   prop: "personageAmount",
        //   isShow: true,
        // },
        {
          label: "本月团队长绩效",
          prop: "teamLeaderAmount",
          isShow: true,
        },
        {
          label: "操作",
          prop: "operate",
          isShow: true,
          width: 100,
          fixed: "right",
          isSlot: true,
        },
      ],
      typesOfConstructionMachineryList: [],
      brandInfoPageList: [],
      partBrandList: [],
      deviceTypeList: [],
      isExpansion: false,
      isImport: false,
      isAddVisible: false,
      editData: null,
      oldSearchData: {},
      operateTitle: "添加",
      machineryEquipmentTypeList: [],
      machineryProductTypeList: [],
      ruleForm: {
        deptId: "",
      },
    };
  },
  created() {},
  computed: {},
  methods: {
    getList(v, pageNum, pageSize, n, type) {
      if (v.startDate) {
        v.startDate = dayjs(v.startDate).startOf("month").format("YYYY-MM-DD");
        v.endDate = dayjs(v.startDate).endOf("month").format("YYYY-MM-DD");
      }
      if (type == "reset") {
        this.ruleForm = {
          deptId: "",
        };
      }
      this.oldSearchData = Object.assign(
        { ...this.ruleForm },
        {
          ...v,
          pageIndex: pageNum,
          pageSize: pageSize,
          fuzzyLookupField: v.keyword,
        }
      );
      getTeamKpiList(this.oldSearchData).then((res) => {
        this.dataList = res.data.records;
        this.totalNum = res.data.total;
      });
    },
    changeGetList() {
      this.$refs.GeneralTable.getList();
    },
    exportData(e) {
      exportTeamKpiList({
        ...this.oldSearchData,
      });
    },
    handleDel(e) {
      if (!e || !e.length) {
        this.$message.error("请选择需要删除的员工！");
        return false;
      }
      this.$confirm("删除后该员工当月的绩效会清除，是否确认删除？", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteStaffKpi(
          e.map((val) => {
            // val.year = val.yearFlag;
            // val.month = val.monthFlag;
            return val;
          })
        ).then((res) => {
          this.changeGetList();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
      });
    },
    editDetail(e) {
      // this.isAddVisible = true;
      // if (e && e.id) {
      //   this.editData = e;
      // }else {
      //   this.editData = null;
      // }
      this.$router.push({
        path: `/routerPerformanceManagement/teamLeaderDetails?id=${
          e.teamLeaderId
        }&year=${Number(
          dayjs(e.yearMonth || new Date()).format("YYYY")
        )}&month=${Number(
          dayjs(e.yearMonth || new Date()).format("MM")
        )}&account=${e.account}`,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.search-filter {
  position: relative;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  margin-bottom: 10px;
  overflow: hidden;
  .product,
  .brand {
    display: flex;
    .label {
      width: 100px;
      height: 28px;
      line-height: 28px;
      white-space: nowrap;
    }
    .list {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      .item {
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        padding: 0 12px;
        border: 1px solid #4278c9;
        margin: 0 15px 15px 0;
        cursor: pointer;
        border-radius: 4px;
        // &:hover {
        //   color: #4278c9;
        //   background-color: #eff6ff;
        // }
      }
      .check-item {
        color: #ffffff;
        background-color: #4278c9;
        border: 1px solid #4278c9;
      }
    }
  }
  .expansion-box {
    position: absolute;
    bottom: 2px;
    left: 0;
    right: 0;
    cursor: pointer;
    font-size: 14px;
    color: #0080ff;
    text-align: center;
  }
}
.slot-list {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  .item {
    width: 250px;
    padding: 10px;
    border: 1px solid #dee3e6;
    border-radius: 6px;
    margin: 0 15px 15px 0;
    .img {
      width: 230px;
      height: 230px;
      margin-bottom: 8px;
    }
    .title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 16px;
      margin-bottom: 8px;
    }
    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
      .modelNum {
        font-size: 14px;
      }
      .price {
        font-size: 18px;
        color: #fda835;
        font-weight: bold;
      }
    }
    .tags {
      display: flex;
      align-items: center;
      .tag {
        margin-right: 8px;
        padding: 5px 8px;
        background-color: #f2f7fd;
        color: #3470ff;
        border-radius: 5px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
